import LazyLoad from "react-lazyload";
import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import ProductFeedList from "./ProductFeedList";
import ProductHightlights from "./ProductHightlights";
import useTranslation from "next-translate/useTranslation";

const useStyles = makeStyles((theme) => ({
  productsOverview: {
    flexGrow: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  highlights: {
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  productsFavorites: {
    ...theme.headline1,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(1),
    },
  },
}));

const ProductOverview = (props) => {
  const { hideFeed, minimizeHighlights, highlightsClass, horizontalScrollClassName } = props;
  const classes = useStyles();
  const { t } = useTranslation("common");

  return (
    <div className={`${classes.productsOverview} ${highlightsClass ? highlightsClass : ""}`}>
      <div className={classes.highlights}>
        <Typography variant="h6" className={classes.productsFavorites}>
          {t("productsFavorites")}
        </Typography>
        <LazyLoad offset={200} placeholder={<Skeleton variant="rect" width={300} height={500} />}>
          <ProductHightlights
            recommendation={{ type: "featured" }}
            minimized={minimizeHighlights}
            horizontalScrollClassName={horizontalScrollClassName}
          />
        </LazyLoad>
      </div>
      {hideFeed != true && <ProductFeedList showFeatured={true} />}
    </div>
  );
};

export default ProductOverview;
