import Analytics from "../../../lib/analytics/analytics";
import Button from "@material-ui/core/Button";
import CardHighlightsHorizontal from "../CardHighlightsHorizontal";
import GenericCard from "../GenericCard";
import OpenIcon from "../../Navigation/Icons/OpenIcon";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import useTranslation from "next-translate/useTranslation";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    backgroundColor: theme.product.imageBackground,
  },
  button: {
    backgroundColor: theme.openButtonColor,
    color: theme.palette.primary.main,
    fontSize: "medium",
    fontWeight: 600,
    margin: theme.cardSpacing,
    marginTop: 0,
  },
  buttonIcon: {
    filter: "invert(100%)",
    fontSize: "12px !important",
  },
}));

const ProductWorlds = (props) => {
  const { highlightsClass, horizontalScrollClassName } = props;
  const classes = useStyles();
  const { t } = useTranslation("common");

  return (
    <CardHighlightsHorizontal
      className={highlightsClass ? highlightsClass : ""}
      horizontalScrollClassName={horizontalScrollClassName}
      title={t("productsWorlds")}
      cards={worlds.map((world, i) => (
        <GenericCard
          key={i}
          overlayContent={true}
          data={{
            localeLinkHref: "/[...generic]",
            localeLinkAs: world.link,
            imageSmall: world.imageSmall,
            imageLarge: world.imageLarge,
            imageAlt: world.name,
          }}
        >
          <div className={classes.cardContent}>
            <Button
              component="div"
              className={classes.button}
              endIcon={<OpenIcon className={classes.buttonIcon} />}
              onClick={() => Analytics.categoryHighlightClick(world.name)}
            >
              {t(`categories:${world.name}`)}
            </Button>
          </div>
        </GenericCard>
      ))}
    />
  );
};

const worlds = [
  {
    name: "Trekking",
    imageSmall: "/images/productpage/Fahrradwelten/Trekking-small.jpg",
    imageLarge: "/images/productpage/Fahrradwelten/Trekking.jpg",
    link: "/trekking-radreise",
  },
  {
    name: "Urban",
    imageSmall: "/images/productpage/Fahrradwelten/Urban-small.jpg",
    imageLarge: "/images/productpage/Fahrradwelten/Urban.jpg",
    link: "/urban",
  },
  {
    name: "Road",
    imageSmall: "/images/productpage/Fahrradwelten/Road-small.jpg",
    imageLarge: "/images/productpage/Fahrradwelten/Road.jpg",
    link: "/road",
  },
  {
    name: "Cyclocross",
    imageSmall: "/images/productpage/Fahrradwelten/Cyclocross-small.jpg",
    imageLarge: "/images/productpage/Fahrradwelten/Cyclocross.jpg",
    link: "/cyclocross",
  },
  {
    name: "Gravel",
    imageSmall: "/images/productpage/Fahrradwelten/Gravel-small.jpg",
    imageLarge: "/images/productpage/Fahrradwelten/Gravel.jpg",
    link: "/gravel",
  },
  {
    name: "Triathlon",
    imageSmall: "/images/productpage/Fahrradwelten/Triathlon-small.jpg",
    imageLarge: "/images/productpage/Fahrradwelten/Triathlon.jpg",
    link: "/triathlon",
  },
  {
    name: "MTB Hardtails",
    imageSmall: "/images/productpage/Fahrradwelten/MTB-Hardtail-small.jpg",
    imageLarge: "/images/productpage/Fahrradwelten/MTB-Hardtail.jpg",
    link: "/mtb-hardtails",
  },
  {
    name: "MTB Fullys",
    imageSmall: "/images/productpage/Fahrradwelten/MTB-Fully-small.jpg",
    imageLarge: "/images/productpage/Fahrradwelten/MTB-Fully.jpg",
    link: "/mtb-fullys",
  },
  {
    name: "MTB Dirt Jump",
    imageSmall: "/images/productpage/Fahrradwelten/MTB-Dirtjump-small.jpg",
    imageLarge: "/images/productpage/Fahrradwelten/MTB-Dirtjump.jpg",
    link: "/mtb-dirt-jump",
  },
  {
    name: "BMX",
    imageSmall: "/images/productpage/Fahrradwelten/BMX-small.jpg",
    imageLarge: "/images/productpage/Fahrradwelten/BMX.jpg",
    link: "/bmx",
  },
  {
    name: "Fitnessräder",
    imageSmall: "/images/productpage/Fahrradwelten/Fitnessraeder-small.jpg",
    imageLarge: "/images/productpage/Fahrradwelten/Fitnessraeder.jpg",
    link: "/fitnessraeder",
  },
  {
    name: "Hollandräder",
    imageSmall: "/images/productpage/Fahrradwelten/Hollandraeder-small.jpg",
    imageLarge: "/images/productpage/Fahrradwelten/Hollandraeder.jpg",
    link: "/hollandraeder",
  },
  {
    name: "Lastenräder",
    imageSmall: "/images/productpage/Fahrradwelten/Lastenraeder-small.jpg",
    imageLarge: "/images/productpage/Fahrradwelten/Lastenraeder.jpg",
    link: "/lastenraeder",
  },
  {
    name: "Cruiser",
    imageSmall: "/images/productpage/Fahrradwelten/Cruiser-small.jpg",
    imageLarge: "/images/productpage/Fahrradwelten/Cruiser.jpg",
    link: "/cruiser",
  },
  {
    name: "Falträder",
    imageSmall: "/images/productpage/Fahrradwelten/Faltraeder-small.jpg",
    imageLarge: "/images/productpage/Fahrradwelten/Faltraeder.jpg",
    link: "/faltraeder",
  },
  {
    name: "Kinder- & Jugendräder",
    imageSmall: "/images/productpage/Fahrradwelten/Kinder-small.jpg",
    imageLarge: "/images/productpage/Fahrradwelten/Kinder.jpg",
    link: "/kinder-jugendraeder",
  },
  {
    name: "City",
    imageSmall: "/images/productpage/Fahrradwelten/City-small.jpg",
    imageLarge: "/images/productpage/Fahrradwelten/City.jpg",
    link: "/city",
  },
  {
    name: "Singlespeed & Fixie",
    imageSmall: "/images/productpage/Fahrradwelten/Singlespeed-small.jpg",
    imageLarge: "/images/productpage/Fahrradwelten/Singlespeed.jpg",
    link: "/singlespeed-fixie",
  },
];

export default ProductWorlds;
