import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import useTranslation from "next-translate/useTranslation";
import GenericCard from "../GenericCard";
import OpenIcon from "../../Navigation/Icons/OpenIcon";
import Button from "@material-ui/core/Button";
import CardHighlightsHorizontal from "../CardHighlightsHorizontal";
import Analytics from "../../../lib/analytics/analytics";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    backgroundColor: "transparent",
  },
  button: {
    backgroundColor: theme.palette.background.secondary,
    color: theme.palette.secondary.main,
    fontSize: "medium",
    fontWeight: 600,
    margin: theme.cardSpacing,
    marginTop: 0,
  },
  buttonIcon: {
    fontSize: "12px !important",
  },
}));

const ProductCategoryHighlights = (props) => {
  const { highlightsClass, horizontalScrollClassName } = props;
  const classes = useStyles();
  const { t } = useTranslation("categoryHighlights");

  return (
    <CardHighlightsHorizontal
      className={highlightsClass ? highlightsClass : ""}
      horizontalScrollClassName={horizontalScrollClassName}
      title={t("productsCategoryHighlights")}
      spotlightCount={2}
      cards={catgories.map((world, i) => (
        <GenericCard
          key={i}
          overlayContent={true}
          data={{
            localeLinkHref: "/[...generic]",
            localeLinkAs: world.link,
            imageSmall: world.imageSmall,
            imageLarge: world.imageLarge,
            imageAlt: world.name,
          }}
          coverImageArea={true}
        >
          <div className={classes.cardContent}>
            <Button
              component="div"
              className={classes.button}
              endIcon={<OpenIcon className={classes.buttonIcon} />}
              onClick={() => Analytics.categoryHighlightClick(world.name)}
            >
              {t(world.name)}
            </Button>
          </div>
        </GenericCard>
      ))}
    />
  );
};

const catgories = [
  {
    name: "E-Bike & Zubehör",
    imageSmall: "/images/productpage/categories/E-Bike-small.jpg",
    imageLarge: "/images/productpage/categories/E-Bike.jpg",
    link: "/e-bikes",
  },
  {
    name: "Bikepacking",
    imageSmall: "/images/productpage/categories/Bikepacking-small.jpg",
    imageLarge: "/images/productpage/categories/Bikepacking.jpg",
    link: "/bikepacking-outdoor",
  },
  {
    name: "Navigation & GPS",
    imageSmall: "/images/productpage/categories/Navigation-small.jpg",
    imageLarge: "/images/productpage/categories/Navigation.jpg",
    link: "/tachos-navigation",
  },
  {
    name: "Bekleidung Oberkörper",
    imageSmall: "/images/productpage/categories/Bekleidung-Oberkoerper-small.jpg",
    imageLarge: "/images/productpage/categories/Bekleidung-Oberkoerper.jpg",
    link: "/oberkoerper",
  },
  {
    name: "Fahrrad-Schuhe",
    imageSmall: "/images/productpage/categories/Fahrrad-Schuhe-small.jpg",
    imageLarge: "/images/productpage/categories/Fahrrad-Schuhe.jpg",
    link: "/rennrad-schuhe",
  },
  {
    name: "Beleuchtung",
    imageSmall: "/images/productpage/categories/Beleuchtung-small.jpg",
    imageLarge: "/images/productpage/categories/Beleuchtung.jpg",
    link: "/beleuchtung",
  },
  {
    name: "Komponenten",
    imageSmall: "/images/productpage/categories/Komponenten-small.jpg",
    imageLarge: "/images/productpage/categories/Komponenten.jpg",
    link: "/komponenten",
  },
  {
    name: "Wearables",
    imageSmall: "/images/productpage/categories/Wearables-small.jpg",
    imageLarge: "/images/productpage/categories/Wearables.jpg",
    link: "/wearables",
  },
  {
    name: "Laufschuhe",
    imageSmall: "/images/productpage/categories/Laufschuhe-small.jpg",
    imageLarge: "/images/productpage/categories/Laufschuhe.jpg",
    link: "/laufschuhe",
  },
  {
    name: "Brillen & Goggles",
    imageSmall: "/images/productpage/categories/Brillen-small.jpg",
    imageLarge: "/images/productpage/categories/Brillen.jpg",
    link: "/brillen-goggles",
  },
];

export default ProductCategoryHighlights;
