import Description, { DescriptionParagraph, DescriptionSection } from "../Description";

import ArticleHighlights from "../Article/ArticleHighlights";
import ButtonLink from "../../Navigation/ButtonLink";
import Head from "next/head";
import LazyLoad from "react-lazyload";
import OpenIcon from "../../Navigation/Icons/OpenIcon";
import ProductCategoryHighlights from "./ProductCategoryHighlights";
import ProductOverview from "./ProductOverview";
import ProductWorlds from "./ProductWorlds";
import React from "react";
import { TheCycleverseTheme } from "src/theme";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import useTranslation from "next-translate/useTranslation";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  root: {
    flexGrow: 1,
  },
  fullWidth: {
    width: "calc(100vw - 12px)",
    position: "relative",
    left: "calc(-50vw + 50%)",
  },
  gutters: {
    marginLeft: theme.globalMarginDesktop,
    marginBottom: theme.spacing(4),
    marginRight: theme.globalMarginDesktop,
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.globalMarginMobile,
      marginRight: theme.globalMarginMobile,
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(8),
    },
  },
  guttersHorizontalScroll: {
    [theme.breakpoints.down("xs")]: {
      marginLeft: -theme.globalMarginMobile,
      marginRight: -theme.globalMarginMobile,
      "& .MuiImageListItem-root:first-child": {
        marginLeft: theme.globalMarginMobile - theme.cardSpacing,
      },
    },
  },
  saleTeaser: {
    backgroundColor: theme.productPageHighlightBackgroundColor,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(5),
    marginBottom: theme.spacing(3),
    textAlign: "center",
  },
  saleTeaserHeadline: {
    ...theme.headline1,
    color: theme.saleColor,
  },
  saleTeaserText: {
    fontSize: 16,
  },
  saleTeaserTextBold: {
    fontWeight: 900,
  },
  saleCTA: {
    marginBottom: theme.spacing(4),

    marginLeft: theme.globalMarginDesktop,
    marginRight: theme.globalMarginDesktop,
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.globalMarginMobile,
      marginRight: theme.globalMarginMobile,
    },
  },
  saleCTAContent: {
    padding: theme.globalMarginMobile,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    color: theme.palette.secondary.main,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundImage: "url(/images/Endspurt-1500.jpg)",
    [theme.breakpoints.down("xs")]: {
      backgroundImage: "url(/images/Endspurt-600.jpg)",
    },
  },
  saleBadge: {
    height: 150,
    width: 150,
    marginTop: -40,
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(4),
    float: "left",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundImage: "url(/images/productpage/Sale-Button.svg)",
    [theme.breakpoints.down("xs")]: {
      height: 110,
      width: 110,
      marginTop: -20,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  saleCTAButton: {
    backgroundColor: theme.saleColor,
    color: theme.palette.primary.main,
    fontSize: "medium",
    fontWeight: 600,
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(2),
    },
  },
  saleCTAHeadline: {
    ...theme.headline1,
    marginBottom: 5,
    fontSize: 30,
    lineHeight: "36px",
    [theme.breakpoints.up("md")]: {
      fontSize: 36,
    },
  },
  saleCTAText: {
    fontSize: 30,
    lineHeight: "30px",
    [theme.breakpoints.up("md")]: {
      fontSize: 36,
    },
  },
  buttonIcon: {
    filter: "invert(100%)",
    fontSize: "12px !important",
  },
  bikeFinderTeaser: {
    textAlign: "center",
    backgroundColor: theme.productPageHighlightBackgroundColor,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(5),
  },
  bikeFinderTeaserIntro: {
    display: "inline-block",
    fontSize: 16,
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
    },
  },
  bikeFinderTeaserButton: {
    backgroundColor: theme.palette.primary.main,
    fontSize: "medium",
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  bikeFinderTeaserButtonIcon: {
    fontSize: "medium !important",
  },
  bikeFinderTitle: {
    ...theme.headline1,
    fontFamily: theme.highlightFontFamily,
    margin: 0,
    fontSize: 36,
    fontWeight: 900,
    marginBottom: 0,
    paddingBottom: 0,
    color: theme.productPageTeaserHeadlineColor,
    [theme.breakpoints.down("sm")]: {
      fontSize: 30,
      lineHeight: "30px",
      paddingBottom: 10,
    },
  },
  bikeFinderLink: {
    fontSize: 20,
    textDecoration: "underline",
  },
  bikeFinderImage: {
    paddingRight: 10,
  },
  bikeCompareTeaser: {
    backgroundColor: theme.palette.primary.main,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    textAlign: "center",
  },
  bikeCompareTeaserEbike: {
    backgroundColor: "unset",
  },
  bikeCompareTeaserButton: {
    backgroundColor: theme.openButtonColor,
    color: theme.palette.primary.main,
    fontSize: "medium",
    fontWeight: 600,
    margin: theme.cardSpacing,
    marginTop: 0,
  },
  bikeCompareTeaserHeadline: {
    fontSize: 36,
    fontWeight: 900,
    [theme.breakpoints.down("sm")]: {
      fontSize: 30,
      lineHeight: "30px",
    },
  },
  bikeCompareTeaserHeadlineEbike: {
    fontFamily: theme.highlightFontFamily,
    marginBottom: theme.spacing(1),
  },
  bikeCompareTeaserImageBiobike: {
    height: 300,
    backgroundImage: "url(/images/Fahrradvergleich-1500-2023.jpg)",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: "url(/images/Fahrradvergleich-600-2023.jpg)",
    },
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  bikeCompareTeaserImageEbike: {
    height: 300,
    backgroundImage: "url(/images/E-Bike_Neuheiten-1500-2023.png)",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: "url(/images/E-Bike_Neuheiten-600-2023.png)",
    },
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  articleHighlights: {
    "& .FX-CardHighlightsHorizontal-title": {
      fontSize: 30,
      lineHeight: "30px",
      paddingBottom: 5,
      color: theme.productPageTeaserHeadlineColor,
    },
  },
  esbitTeaserContent: {
    marginTop: 5,
    position: "relative",
    height: 730,
    backgroundImage: "url(/images/productpage/Esbit-1500.jpg)",
    [theme.breakpoints.down("sm")]: {
      height: 400,
      backgroundImage: "url(/images/productpage/Esbit-600.jpg)",
    },
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  esbitTeaserAction: {
    position: "absolute",
    bottom: 0,
    left: 0,
    margin: 5,
  },
  esbitTeaserText: {
    fontSize: 36,
    fontWeight: 900,
    color: theme.palette.primary.main,
    paddingLeft: 3,
    [theme.breakpoints.down("sm")]: {
      fontSize: 30,
      lineHeight: "30px",
    },
  },
  esbitTeaserButton: {
    backgroundColor: theme.palette.primary.main,
    fontSize: "medium",
    fontWeight: 600,
    margin: theme.cardSpacing,
    marginTop: 0,
  },
  linebreak: {
    display: "inline-block",
  },
  buyingAdvice: {
    textAlign: "center",
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
  },
  buyingAdviceHeadline: {
    fontFamily: "inherit",
    fontWeight: 900,
    fontSize: 36,
    lineHeight: "40px",
    maxWidth: 700,
    display: "inline-block",
    marginBottom: theme.spacing(3),
  },
}));

const Products = () => {
  const classes = useStyles();
  const { t } = useTranslation("descriptionProduct");

  return (
    <>
      <Head>
        <link rel="preload" href="https://images.thecycleverse.com/Clara-mit-Kreis.svg" as="image" />
      </Head>
      <div className={classes.root} data-test="page-">
        <div className={`${classes.bikeFinderTeaser} ${classes.fullWidth}`}>
          <img
            loading="lazy"
            src="https://images.thecycleverse.com/Clara-mit-Kreis.svg"
            width={55}
            height={55}
            alt={"Bike Finder Image"}
            className={classes.bikeFinderImage}
          />
          <div className={classes.bikeFinderTeaserIntro}>
            <h2 className={classes.bikeFinderTitle}>
              <span className={classes.linebreak}>{t("productsBikeFinderTitle1")}</span>{" "}
              <span className={classes.linebreak}>{t("productsBikeFinderTitle2")}</span>
            </h2>
            <div>{t("productsBikeFinderText")}</div>
          </div>
          <div>
            <ButtonLink
              href="/welches-fahrrad-passt-zu-mir"
              as="/welches-fahrrad-passt-zu-mir"
              buttonClassName={classes.bikeFinderTeaserButton}
              endIcon={<OpenIcon className={classes.bikeFinderTeaserButtonIcon} />}
            >
              {t("productsBikeFinderLinkBiobike")}
            </ButtonLink>
            <ButtonLink
              href="/welches-ebike-passt-zu-mir"
              as="/welches-ebike-passt-zu-mir"
              buttonClassName={classes.bikeFinderTeaserButton}
              endIcon={<OpenIcon className={classes.bikeFinderTeaserButtonIcon} />}
            >
              {t("productsBikeFinderLinkEbike")}
            </ButtonLink>
          </div>
        </div>

        <div className={`${classes.bikeCompareTeaser} ${classes.fullWidth}`}>
          <h2 className={classes.bikeCompareTeaserHeadline}>
            <span className={classes.linebreak}>{t("productsBikeCompareTitleBioBike1")}</span>{" "}
            <span className={classes.linebreak}>{t("productsBikeCompareTitleBioBike2")}</span>
          </h2>
          <div className={classes.bikeCompareTeaserImageBiobike} />
          <ButtonLink
            href="/fahrrad-vergleich"
            as="/fahrrad-vergleich"
            buttonClassName={classes.bikeCompareTeaserButton}
            endIcon={<OpenIcon className={classes.buttonIcon} />}
          >
            {t("productsBikeCompareLinkBioBike")}
          </ButtonLink>
        </div>

        <div className={`${classes.bikeCompareTeaser} ${classes.bikeCompareTeaserEbike}`}>
          <h2 className={`${classes.bikeCompareTeaserHeadline} ${classes.bikeCompareTeaserHeadlineEbike}`}>
            {t("productsBikeCompareTitleEBike")}
          </h2>
          <div className={classes.bikeCompareTeaserImageEbike} />
          <ButtonLink
            href="/products/search?s=e-bike+2024"
            as="/products/search?s=e-bike+2024"
            buttonClassName={classes.bikeCompareTeaserButton}
            endIcon={<OpenIcon className={classes.buttonIcon} />}
          >
            {t("productsBikeCompareLinkEBike")}
          </ButtonLink>
        </div>

        <div className={`${classes.saleTeaser} ${classes.fullWidth}`} data-test="sale-teaser">
          <Typography variant="h6" className={classes.bikeFinderTitle}>
            {t("productsSaleTeaserHeadline")}
          </Typography>
          <div className={classes.saleTeaserText}>
            <span>{t("productsSaleTeaserText1")}</span>
            <span className={classes.saleTeaserTextBold}>{t("productsSaleTeaserText2")}</span>
            <span>{t("productsSaleTeaserText3")}</span>
            <span className={classes.saleTeaserTextBold}>{t("productsSaleTeaserText4")}</span>
            <span>{t("productsSaleTeaserText5")}</span>
          </div>
        </div>

        <ProductWorlds highlightsClass={classes.gutters} horizontalScrollClassName={classes.guttersHorizontalScroll} />

        <div className={classes.saleCTA}>
          <div className={classes.saleBadge} />
          <div className={classes.saleCTAContent}>
            <Typography variant="h6" className={classes.saleCTAHeadline}>
              {t("productsSaleTeaserCTA1")}
            </Typography>
            <Typography variant="body1" className={classes.saleCTAText}>
              {t("productsSaleTeaserCTA2")}
            </Typography>
            <ButtonLink
              href="/products/sale"
              as="/products/sale"
              buttonClassName={classes.saleCTAButton}
              endIcon={<OpenIcon className={classes.buttonIcon} />}
            >
              {t("productsSaleTeaserCTAButton")}
            </ButtonLink>
          </div>
        </div>

        <div className={classes.buyingAdvice}>
          <Typography variant="h6" className={classes.buyingAdviceHeadline}>
            {t("productBuyingAdviceHeadline")}
          </Typography>
          <div>
            <ButtonLink
              href="/blog"
              as="/blog"
              buttonClassName={classes.bikeCompareTeaserButton}
              endIcon={<OpenIcon className={classes.buttonIcon} />}
            >
              {t("productBuyingAdviceBlogButton")}
            </ButtonLink>
            <ButtonLink
              href="/online-kaufberatung"
              as="/online-kaufberatung"
              buttonClassName={classes.bikeCompareTeaserButton}
              endIcon={<OpenIcon className={classes.buttonIcon} />}
            >
              {t("productBuyingAdviceToolsButton")}
            </ButtonLink>
          </div>
        </div>

        <ArticleHighlights
          highlightsClass={`${classes.gutters} ${classes.articleHighlights}`}
          horizontalScrollClassName={classes.guttersHorizontalScroll}
          largeCardOnDesktop={false}
          title={t("common:articleHighlights")}
        />

        <div className={`${classes.gutters}`}>
          <div className={classes.bikeCompareTeaserHeadline}>{t("productsSaleTeaserEsbitHeadline")}</div>
          <LazyLoad>
            <div className={classes.esbitTeaserContent}>
              <div className={classes.esbitTeaserAction}>
                <div className={classes.esbitTeaserText}>{t("productsSaleTeaserEsbitText")}</div>
                {/* <a href="https://esbit.de" target="blank" style={{textDecoration: 'none'}}>
                <Button
                    className={classes.esbitTeaserButton}
                    endIcon={<OpenIcon className={classes.bikeFinderTeaserButtonIcon} />}
                  >
                    {t("productsSaleTeaserEsbitButton")}
                </Button>
              </a> */}
                <ButtonLink
                  href="/products/search?s=Esbit"
                  as="/products/search?s=Esbit"
                  buttonClassName={classes.esbitTeaserButton}
                  endIcon={<OpenIcon className={classes.bikeFinderTeaserButtonIcon} />}
                >
                  {t("productsSaleTeaserEsbitButton")}
                </ButtonLink>
              </div>
            </div>
          </LazyLoad>
        </div>

        <ProductCategoryHighlights
          highlightsClass={classes.gutters}
          horizontalScrollClassName={classes.guttersHorizontalScroll}
        />

        <ProductOverview
          hideFeed={true}
          minimizeHighlights={true}
          highlightsClass={classes.gutters}
          horizontalScrollClassName={classes.guttersHorizontalScroll}
        />

        <Description className={classes.gutters}>
          <DescriptionSection title={t("descriptionProductSection1Title")}>
            <DescriptionParagraph>
              <b>{t("descriptionProductSection1Text1")}</b>
              {t("descriptionProductSection1Text2")}
            </DescriptionParagraph>
            <DescriptionParagraph>
              <b>{t("descriptionProductSection1Text3")}</b>
              {t("descriptionProductSection1Text4")}
            </DescriptionParagraph>
          </DescriptionSection>
          <DescriptionSection title={t("descriptionProductSection2Title")}>
            <DescriptionParagraph>{t("descriptionProductSection2Text1")}</DescriptionParagraph>
            <DescriptionParagraph>{t("descriptionProductSection2Text2")}</DescriptionParagraph>
            <DescriptionParagraph>{t("descriptionProductSection2Text3")}</DescriptionParagraph>
          </DescriptionSection>
          <DescriptionSection title={t("descriptionProductSection3Title")}>
            <DescriptionParagraph>{t("descriptionProductSection3Text1")}</DescriptionParagraph>
          </DescriptionSection>
          <DescriptionSection title={t("descriptionProductSection4Title")}>
            <DescriptionParagraph>{t("descriptionProductSection4Text1")}</DescriptionParagraph>
          </DescriptionSection>
        </Description>
      </div>
    </>
  );
};

export default Products;
