import * as React from "react";

import MainLayout from "src/components/Layout/MainLayout";
import NewsletterCTA from "src/components/Newsletter/NewsletterCTA";
import Products from "src/components/Content/Product/Products";
import SeoHead from "src/components/Seo/SeoHead";
import useTranslation from "next-translate/useTranslation";

const Index = () => {
  const { t, lang: locale } = useTranslation("common");

  return (
    <MainLayout searchBar="productStickyHighlight">
      <SeoHead
        description={t("seoDescriptionDefault")}
        schema={`{
          "@context" : "http://schema.org",
          "@type" : "Organization",
          "name" : "The Cycleverse",
        "url" : "https://thecycleverse.com/de",
        "sameAs" : [
          "https://www.facebook.com/thecycleverse/",
          "https://www.instagram.com/thecycleverse/",
          "https://www.pinterest.de/TheCycleverse"
          ]
        }`}
      />
      <NewsletterCTA layout="general" page="landingpage" />
      <Products />
    </MainLayout>
  );
};

export default Index;
